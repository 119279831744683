// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA1X8kQ89yt23tAWMvqIBmYS_kE_W4VKew",
  authDomain: "cash-trend.firebaseapp.com",
  projectId: "cash-trend",
  storageBucket: "cash-trend.appspot.com",
  messagingSenderId: "932823706781",
  appId: "1:932823706781:web:680a06612a3a41388e7874",
  measurementId: "G-2PWSCHZV8W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export type AnalyticsEventName = "load";

export const logAnalyticsEvent = (
  eventName: AnalyticsEventName,
  params?: { [key: string]: any }
) => logEvent(analytics, eventName, params);
